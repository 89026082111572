<template>
  <iframe
    ref="pcFrame"
    :src="getUrl(playCanvas)"
    class="div-100"
    frameborder="0"
    width="100%"
    height="100%"
    @load="onFrameLoaded"
  ></iframe>
</template>

<script>
let g_playcanvasApp = undefined;
import { urlHelper } from "_shared/helpers";
// import EventBus from "_shared/EventBus";
import { mapGetters } from "vuex";

export default {
  mixins: [urlHelper],
  data: () => ({
    playCanvas: "about:blank",
  }),
  async mounted() {
    this.playCanvas = (
      await this.$axios.get("api/webapp/playcanvas")
    ).data.value;
  },
  beforeUnmount() {
    this.DisconnectPlaycanvas();
  },
  methods: {
    onFrameLoaded() {
      if (this.$refs.pcFrame.contentWindow.pc != undefined)
        this.ConnectPlaycanvas();
    },
    /* playcanvas: connect, disconnect */
    async ConnectPlaycanvas() {
      if (g_playcanvasApp !== undefined) {
        this.DisconnectPlaycanvas();
      }

      let pc = this.$refs.pcFrame.contentWindow.pc;

      g_playcanvasApp = pc.Application.getApplication();
      g_playcanvasApp.on("state:changed", this.onStateChanged);
      g_playcanvasApp.on("vue:showOverlay", this.onShowOverlay);
      g_playcanvasApp.on("postinitialize", this.PlayCanvasPostInit);
      g_playcanvasApp.on("vue:showBackBtn", this.ShowBackBtn);

      g_playcanvasApp.on("net:sendSyncData", this.SyncData);
    },
    PlayCanvasPostInit() {
      // urlPrefix wird beim pfad zu preview-bildern voran gestellt
      // g_playcanvasApp.fire("cfg:urlPrefix", "http://localhost:5001");
      g_playcanvasApp.fire("cfg:urlPrefix", "/identity");

      // netMode: 0 = client, 1 = master, 2 = off
      g_playcanvasApp.fire(
        "cfg:netMode",
        this.hasRole("manager.owner") ? 1 : 0
      );

      this.loadRoomdata();
    },
    onStateChanged(state){      
      this.$store.commit('machine/SET_Category', state.newState);
    },
    DisconnectPlaycanvas() {
      if (g_playcanvasApp === undefined) return;

      g_playcanvasApp.off("vue:showOverlay", this.onShowOverlay);
      g_playcanvasApp.off("state:changed", this.onSlideShow);
      g_playcanvasApp.off("postinitialize", this.PlayCanvasPostInit);
      g_playcanvasApp.off("net:sendSyncData", this.SyncData);
      g_playcanvasApp.off("vue:showBackBtn", this.ShowBackBtn);

      g_playcanvasApp = undefined;
    },
    ShowBackBtn(doShow) {
      console.log("backBtn: " + doShow);
      this.$parent.onShowBackBtn(doShow);
    },
    SendPopupState(isOpen) {
      // isOpen = bool
      g_playcanvasApp.fire("vue:popupState", isOpen);
    },
    onShowOverlay(data) {
      // wenn data undefined -> popup schließen
      console.log( data );
      this.$parent.onShowOverlay(data);
    },
    SyncData(syncData) {
      this.$syncroomhub.send_Data(syncData);
    },
    /* fire events at playcanvas */
    SetState(newState) {
      g_playcanvasApp.fire("state:goto", { newState: newState });
    },
    loadRoomdata() {
      let data = this.$store.getters["machine/GET_SelectedMachineData"];
      if (data && g_playcanvasApp) {
        console.log("load: " + this.GET_SelectedMachine.id);
        g_playcanvasApp.fire("state:loadData", data);
      }
    },
  },
  computed: {
    ...mapGetters("machine", [
      "GET_Category",
      "GET_SelectedMachine",
      "GET_SelectedMachineData",
      "GET_SyncData",
    ]),
  },
  watch: {
    GET_Category: {
      handler(val) {
        if (val != undefined) this.SetState(val);
      },
    },
    GET_SelectedMachineData: {
      handler(val) {
        if (val != undefined) this.loadRoomdata();
      },
    },
    GET_SyncData: {
      handler(val) {
        if (g_playcanvasApp != undefined)
          g_playcanvasApp.fire("net:syncData", val);
      },
      //deep:true //ggf. deep watch wenn nested data
    },
  },
};
</script>

<style>
</style>