<template>
  <div class="v-toolbar__content">
    <v-menu offset-y close-on-click close-on-content-click>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on" color="white">
          <v-icon>mdi-web</v-icon>
        </v-btn>
      </template>
      <v-card flat>
        <v-item-group>
          <v-list-item v-for="lang in languages" :key="lang.lang" @click="setLanguage(lang.lang + '-' + lang.lang.toUpperCase())" style="padding: 0; margin: 0;">
            <v-btn text>{{lang.lang.toUpperCase()}}</v-btn>
          </v-list-item>
        </v-item-group>
      </v-card>
    </v-menu>
  </div>
</template>

<script>

export default {
  computed: {
    languages() {
      return this.$store.getters['i18n/Languages'];
    }
  },
  methods:{
    setLanguage(value)
    {      
      this.$store.dispatch('i18n/setLanguage',{lang:value, clientid:this.$store.state.client_id })      
    }
  }
};
</script>

<style>
</style>