<template>
  <!--v-if="eventItem.forcedState != 'done'"-->
  <v-card
    
    flat
    min-height="310"  
    max-width="546"  
    transition="fade-transition"
  >
    <v-row no-gutters>

      <v-col cols="3" sm="6" class="d-flex flex-column">
        
        <v-img
          
          height="100%"
          min-height="310"
          aspect-ratio="1/1"
          :src="getUrl(eventItem.eventMetaInfo.imageUrl)"
        >
        <template v-slot:placeholder>
          <v-sheet class="div-100" color="white"/>
        </template>
          <div class="pa-4">
            <v-icon color="white">mdi-calendar</v-icon>
          </div>
        </v-img>
      </v-col>

      <v-col cols="9" sm="6" class="d-flex flex-column">       
          <div>
            <v-card-title class="font-weight-black" style="line-height: 1.5rem">
              <p v-html="eventItem.eventMetaInfo.title"></p>
            </v-card-title>

            <v-card-subtitle>
              <p v-html="eventItem.eventMetaInfo.date"></p>
            </v-card-subtitle>

            <v-card-text>
              <p v-html="eventItem.eventMetaInfo.description"></p>
            </v-card-text>
          </div>
          <v-card-actions class="div-100 justify-end align-end">
            <v-btn              
              min-width="150"
              elevation="0"
              color="accent"
              class="mx-1 mb-1 white--text rounded-pill"
              :to="`/${eventItem.id}`"
              v-html="$t('page.events.btn-info')"
            >
            </v-btn>
          </v-card-actions>        
      </v-col>
    </v-row>

  </v-card>
</template>

<script>
import { DateMixins, urlHelper } from "_shared/helpers";

export default {
  props: {
    eventItem: Object,
  },
  mixins: [DateMixins, urlHelper],
};
</script>

<style lang="scss" scoped>
p {
  margin-bottom: 0px;
}
</style>