<template>
  <v-app v-if="$te('page')">
    <v-app-bar
      :color="$store.state.machine.header"
      app
      clipped-right
      clipped-left
      dense
      flat
      style="z-index:10"
    >
      <!-- <v-app-bar-nav-icon dark @click="drawerTabs=!drawerTabs"></v-app-bar-nav-icon> -->

      <router-link class="d-flex align-center ml-3" to="/">
        <v-img
          class="shrink mr-2"
          contain
          :src="require('_shared/assets/logo_wirtgen_group.svg')"
          transition="fade-transition"
          width="122"
        />
      </router-link>

      <!-- <v-spacer /> -->
      <ToolbarTabs
        v-if="$vuetify.breakpoint.lgAndUp"
        style="max-width: 1248px"
        class="mx-auto"
      />
      <v-spacer v-if="$vuetify.breakpoint.lgAndDown"/>
      <ToolbarUser />
      <ToolbarLanguage
        v-if="this.$store.getters['i18n/Languages'].length > 1"
      />
      <v-app-bar-nav-icon
        class="white--text"
        @click="drawer = !drawer"
        v-if="hasRole('manager.owner') && $router.currentRoute.name == 'Live'"
      >
        <v-icon>mdi-dots-vertical</v-icon>
      </v-app-bar-nav-icon>
    </v-app-bar>
    <DrawerMachine
      v-bind:drawer.sync="drawer"
      v-if="hasRole('manager.owner')"
    />

    <!-- <v-navigation-drawer app
    clipped temporary
    left
    bottom
    color="grey"
    v-model="drawerTabs">
      <ToolbarTabs :vertical="true" />
    </v-navigation-drawer> -->
    <v-main>
      <!-- <ConsentView /> -->
      <router-view />
    </v-main>

    <!-- <v-footer
      color="secondary"
      v-if="this.$router.currentRoute.matched.some(({ name }) => 'account')"
    >
      <FooterView :light="true" :useCredits="true" />
    </v-footer> -->
  </v-app>
</template>

<script>
/*eslint-disable vue/no-unused-components*/

import ToolbarUser from "_shared/account/components/ToolbarUser";
import ToolbarLanguage from "_shared/i18n/components/ToolbarLanguage";
import ToolbarTabs from "./views/Toolbar/ToolbarTabs";
import DrawerMachine from "./views/Toolbar/DrawerMachine";

import ConsentView from "_shared/components/ConsentView";
import FooterView from "_shared/Footer/FooterView.vue";
import { mapGetters } from "vuex";
// import { mapGetters } from 'vuex';

export default {
  name: "App",
  components: {
    ToolbarUser,
    FooterView,
    ToolbarLanguage,
    ConsentView,
    ToolbarTabs,
    DrawerMachine,
  },
  data: () => ({
    drawer: false,
    drawerTabs:true,
  }),
  computed: {
    ...mapGetters("machine", ["GET_Machines"]),
  },
  watch: {
    drawer: {
      handler(va) {
        console.log(va);
      },
    },
    GET_Machines: {
      handler(v) {
        if (v.length == 0) this.drawer = false;
      },
    },
  },
};
</script>

<style lang="scss">
.component-header {
  margin-top: 0px;
  margin-bottom: 0px;
  line-height: 2.8rem;
  text-align: left;
  color: var(--v-primary-base);
}
</style>
