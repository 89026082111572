import Vue from 'vue'
import { machine } from './store'

const plugin = {
    install(vue, store) {

        const signalR = require('@microsoft/signalr')

        const connection = new signalR.HubConnectionBuilder()
            .withUrl(process.env.VUE_APP_ID4Server + "/hubs/syncroomhub")
            // , { accessTokenFactory: () => store.getters['account/user']?.access_token })
            .configureLogging(signalR.LogLevel.Warning)
            .withAutomaticReconnect()
            .build();

        connection.on("receive_syncData", async (data) => {
            store.commit('machine/SET_SyncData', data);            
        });

        connection.on("receive_syncCategory", async (data) => {
            store.commit('machine/SET_Category', data);            
        });

        connection.on("receive_syncMachine", async (data) => {
            let item = store.getters["machine/GET_Machines"].find(s => s.id == data);
            store.dispatch("machine/loadMachineData", item);
        });

        connection.on("receive_syncContent", async (data) => {
            console.log(data)
            store.commit('machine/SET_SyncContent', data); 
            // let item = store.getters["machine/GET_Machines"].find(s => s.id == data);
            // store.dispatch("machine/loadMachineData", item);
        });


        const hubFunctions = {
            start: async () => {
                try {
                    if (connection.state == signalR.HubConnectionState.Disconnected) {
                        await connection.start();
                        console.log("syncroomhub - New SignalR Connected.");
                    } else
                        console.log("syncroomhub - SignalR Connected.");
                } catch (err) {
                    console.log(err);
                    setTimeout(hubFunctions.start, 5000);
                    return;
                }
            },
            stop: () => {
                connection.stop();
            },
            joinGroupRoom: async (eventid) => {
                return await connection.invoke('joinGroupRoom', eventid);
            },
            leaveGroupRoom: async (eventid) => {
                return await connection.invoke('leaveGroupRoom', eventid);
            },
            send_Data: async (data) => {
                await connection.invoke('send_syncData', data, store.getters['EventId']);
            },
            send_Machine: async (data) => {
                await connection.invoke('send_syncMachine', data, store.getters['EventId']);
            },
            send_Category: async (data) => {
                await connection.invoke('send_syncCategory', data, store.getters['EventId']);
            },
            send_Content: async (data) => {
                await connection.invoke('send_syncContent', data, store.getters['EventId']);
            }

        };


        Vue.syncroomhub = hubFunctions
        Vue.prototype.$syncroomhub = hubFunctions
        window.syncroomhub = hubFunctions;

        if (!store.hasModule('machine')) {
            store.registerModule('machine', machine);
        }

    }
}

export default plugin;