<template>
  <v-navigation-drawer
    app
    clipped
    right
    bottom
    mobile-breakpoint="xs"
    disable-resize-watcher
    v-model="drawer1"
    temporary
    :style="`margin-top: ${$vuetify.application.top}px;`"
    :height="`calc(100vh - ${$vuetify.application.top}px)`"
  >
    <v-item-group
      v-model="selectedItem"
      :mandatory="selectedItem != undefined"
      class="py-6 px-6"
      @change="selected"
    >
      <v-row>
        <v-col
          v-for="item in $store.getters['machine/GET_Machines']"
          :key="item.id"
        >
          <v-item v-slot="{ active, toggle }">
            <v-sheet
              class="ma-3"
              :ripple="false"
              flat
              color="transparent"
              @click="toggle"
            >
              <v-img :src="getUrl(item.image)"></v-img>
              <v-btn
                class="mt-2"
                block
                rounded
                :class="active ? 'white--text' : 'black-text'"
                :outlined="!active"
                :color="item.color"
                >{{ item.name }}</v-btn
              >
            </v-sheet>
          </v-item>
        </v-col>
      </v-row>
    </v-item-group>
  </v-navigation-drawer>
</template>

<script>
import { urlHelper } from "_shared/helpers";
export default {
  mixins: [urlHelper],
  props: {
    drawer: { Type: Boolean, required: true },
  },
  data: () => ({
    selectedItem: undefined,
    drawerTimeout: undefined,
    drawer1: false,
  }),
  methods: {
    async selected(itemId) {
      if (this.drawerTimeout != undefined) clearTimeout(this.drawerTimeout);
      let item = this.$store.getters["machine/GET_Machines"][itemId];
      this.$store.dispatch("machine/loadMachineData", item);
      if (item != undefined)
        await this.$syncroomhub.send_Machine(
          item.id,
          this.$store.getters["EventId"]
        );
      this.drawerTimeout = setTimeout(async () => {
        this.$emit("update:drawer", false);
      }, 300);
    },
  },
  watch: {
    drawer1: {
      handler(val) {
        this.$emit("update:drawer", val);
      },
    },
    drawer: {
      handler(val) {
        this.drawer1 = val;
        if (this.drawerTimeout != undefined) clearTimeout(this.drawerTimeout);
      },
    },
  },
};
</script>

<style>
</style>