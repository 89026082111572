
import Vue from 'vue'
import Vuex from 'vuex'
import {storeEvent} from '_shared/Event/store'
// import {machine} from './machine'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    client_id: 'webapp.client',  
    coloredHeader:['Live', 'WaitingRoom','Mediathek', 'Home'],
    company: 'wirtgen',
    companies: ['wirtgen', 'kleemann'],
    selectedMachine: undefined,
    machines: [],
    currentColor: '#fb5a02',
  },
  modules:{
    event:storeEvent,
    // machine:machine,
  },
  getters: {
    getSelectedMachine: state => state.selectedMachine
  }
});