<template>
  <v-container fluid class="d-flex flex-column align-center">
    <!-- <div      
      v-if="!$store.getters['account/isLogIn']"
    >
      <v-btn
        color="accent"        
        x-large
        
        class="mt-12 white--text rounded-pill"
        @click="register"
        >{{ $t("home.btn_register") }}</v-btn
      >
      <a class="txt-a white--text mt-2" @click="login">
        {{ $t("home.txt_Login") }}
      </a>
    </div> -->
<!-- v-else-if="this.$store.state.event.eventItems.length === 1" -->
    <v-btn
      color="accent"
      x-large
      min-width="200"
      class="mt-6 white--text rounded-pill"
      @click="enter"
      
    >
      {{ $t("home.btn_enter") }}
    </v-btn>

  </v-container>
</template>

<script>
export default { 
  methods: {
    enter() {
      this.$store.commit(
        "SET_EventId",
        this.$store.state.event.eventItems[0].id
      );
      this.$router.push({
        name: "WaitingRoom",
        params: { eventId: this.$store.state.event.event_id },
      });
    },
    register() {
      this.$account.register();
    },
    login() {
      this.$account.login();
    },
  },
};
</script>

<style>
</style>