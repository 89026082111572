
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { storei18n } from './store'

Vue.use(VueI18n)


export const i18n = new VueI18n({
  locale: 'de',
  fallbackLocale: 'de', 
  silentFallbackWarn: true, 
  // missing: () => {
  //   return ''
  // },
})

const loadedLanguages = []

function setI18nLanguage(lang) {
  i18n.locale = lang
  window.axios.defaults.headers.common['Accept-Language'] = lang
  document.querySelector('html').setAttribute('lang', lang)
  return lang
}

export function ClearLoadedLanguage()
{
  loadedLanguages.splice(0,loadedLanguages.length);
}

export function loadLanguageAsync(lang, clientid) {
  
  if (loadedLanguages.includes(lang)) {
    if (i18n.locale !== lang) setI18nLanguage(lang)
    return Promise.resolve()
  }else
  return window.axios.get(`/api/webapp/i18n?clientid=${clientid}&key=${lang}`).then(response => {
    
    if (response.data.data != null) {
      // let msgs = JSON.parse(response.data.data)
       let msgs = response.data.data     

      loadedLanguages.push(lang)
      i18n.setLocaleMessage(lang, msgs)
      
      setI18nLanguage(lang)
    }
  })
}


const plugin = {
  install(Vue, store) {
    if (!store.hasModule('i18n')) {
      store.registerModule('i18n', storei18n);
    }
  }
}

export default plugin;

