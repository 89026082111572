<template>
  <div class="div-100">
    <PlayCanvas class="playcanvas" />
    <v-container class="div-100" id="Content-Frame">
      <transition name="component-fade" mode="out-in">
        <component
          :is="typeOverlay"
          v-on:close="closePopup"
          v-on:collectionItemSelected="collectionItemSelected"
          :dataOverlay="dataOverlay"
          :key="componentKey"
        ></component>
      </transition>
      <div
        style="position: fixed; bottom: 0px; left: 0px; z-index: 10"
        class="div-100x d-flex justify-center"
        v-if="showBackBtn"
      >
        <v-btn
          @click="goBack"
          class="mb-4"
          small
          fab
          :color="$store.state.machine.header"
        >
          <v-icon class="white--text">mdi-chevron-down</v-icon>
        </v-btn>
      </div>
      <!--  -->
    </v-container>
  </div>
</template>

<script>
/*eslint-disable*/
import PlayCanvas from "../playCanvas.vue";
// import EventBus from "_shared/EventBus";
import SliderPopup from "../components/Popups/SliderPopup.vue";
import CollectionPopup from "../components/Popups/CollectionPopup.vue";
import MachinePopup from "../components/Popups/MachinePopup.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    PlayCanvas,
    SliderPopup,
    CollectionPopup,
    MachinePopup,
  },
  data: () => ({
    typeOverlay: undefined,
    dataOverlay: undefined,
    lastData: undefined,
    showBackBtn: false,
  }), 
  async created() {
    await this.$syncroomhub.start();

    let roomStateInfo = await this.$syncroomhub.joinGroupRoom(
      this.$store.getters["EventId"]
    );

    this.$store.commit("machine/SET_RoomStateInfo", roomStateInfo);
    await this.$store.dispatch("machine/getMachines");

    this.$store.dispatch(
      "machine/loadMachineData",
      this.$store.getters["machine/GET_Machines"].find(
        (s) => s.id == roomStateInfo.states.machine
      )
    );
    this.$store.commit("machine/SET_Category", roomStateInfo.states.category);
  },
  async beforeDestroy() {
    // await this.$syncroomhub.leaveGroupRoom(this.$store.state.machine.roomStateInfo.group);
    await this.$syncroomhub.stop();

    this.$store.commit("machine/SET_Machines", []);
    this.$store.commit("machine/SET_MachineData", undefined);
    this.$store.commit("machine/SET_SelectedMachine", undefined);
  },
  computed: {
    ...mapGetters("machine", ["GET_SyncContent", "GET_SelectedMachineData"]),
    componentKey() {
      if (this.dataOverlay != undefined) return this.dataOverlay.id;
    },
  },
  methods: {
    goBack() {
      this.$store.commit("machine/SET_Category", 0);
      this.typeOverlay = undefined;
    },
    onShowBackBtn(data) {
      this.showBackBtn = data;
    },
    closePopup() {
      if (this.lastData != undefined) {
        this.onShowOverlay(this.lastData);
      } else {
        if (this.typeOverlay != "MachinePopup")
          this.showBackBtn = true;
        this.typeOverlay = undefined;
      }
    },
    onShowOverlay(data) {
      if (data == undefined) {
        this.typeOverlay = undefined;
      } else {
        console.log(data.type);

        this.showBackBtn = false;
        this.dataOverlay = data;
        this.lastData = undefined;

        let content = JSON.stringify({
          state: this.$store.getters["machine/GET_Category"],
          id: this.dataOverlay.id,
          header: undefined,
          type: data.type,
        });
        this.$store.commit("machine/SET_SyncContent", content);
        this.$syncroomhub.send_Content(content);

        switch (data.type) {
          case "slideshow":
            this.typeOverlay = "SliderPopup";
            break;
          case "collection":
            this.typeOverlay = "CollectionPopup";
            break;
          case "machinePopup":
            this.typeOverlay = "MachinePopup";
            break;
        }
      }
    },
    collectionItemSelected(data) {
      if (data == undefined) {
        this.typeOverlay = undefined;
      } else {
        this.lastData = Object.create(this.dataOverlay);
        let content = JSON.stringify({
          state: this.$store.getters["machine/GET_Category"],
          id: this.dataOverlay.id,
          header: data.header,
          type: data.type,
        });
        this.$store.commit("machine/SET_SyncContent", content);
        this.$syncroomhub.send_Content(content);

        this.dataOverlay = data;

        switch (data.type) {
          case "slideshow":
            this.typeOverlay = "SliderPopup";
            break;
        }
      }
    },
  },
  watch: {
    GET_SyncContent: {
      handler(val) {
        console.log(val);
        // this.dataOverlay=this.GET_SelectedMachineData[val.]
      },
      deep: true,
    },
  },
};
</script>

<style>
.playcanvas {
  position: absolute;
}

.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.3s ease;
}
.component-fade-enter, .component-fade-leave-to
/* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>