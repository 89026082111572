<template>

  <div class="div-100 d-flex align-center">
    <v-card rounded="xl" light class="pa-8" style="top: -128px" max-width="512" elevation="15">
      <v-row>
        <v-col cols="10">
          <v-card-subtitle class="mb-3">
            <h2>{{ dataOverlay.header }}</h2>
          </v-card-subtitle>
          <v-card-text>
            <!-- <p v-html="dataOverlay.body"></p> -->
            <ul>
            <li v-for="txt in dataOverlay.body" :key="txt" v-html="txt"></li>
            </ul>
          </v-card-text>
        </v-col>
        <v-col cols="2" class="d-flex">
          <v-btn
            @click="$emit('close')"
            :color="$store.state.machine.header"
            small
            fab
            elevation="0"
            class="ml-auto mt-n3 mr-n3"
            
          >
            <v-icon class="white--text">mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </div>

</template>

<script>
export default {
  props: {
    dataOverlay: { type: Object, required: true },
  }
};
</script>

<style scoped>
p {
  margin-bottom: 0px;
}
</style>