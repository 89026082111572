<template>
  <v-card flat color="transparent" class="my-10 "  v-if="AgendaItems && AgendaItems.length > 0"> 
    
    <v-card-title :class="center ? 'justify-center' : 'justify-start'">
      <h1 v-if="!smallheadline" class="component-header" v-html="$t('event.agendatitle')"> </h1>
      <h3 v-if="smallheadline" class="component-header" v-html="$t('event.agendatitle')"> </h3>
    </v-card-title>

    <v-card-text width="100%"
      class="d-flex py-5 px-0"
      :class="center ? 'justify-center' : 'justify-start'"
    >
      <v-timeline dense align-top class="ma-0 " v-if="AgendaItems && AgendaItems.length > 0">
        <v-timeline-item small v-for="item in AgendaItems" :key="item.id">
          <v-row>
            <v-col cols="auto" class="pt-0 align-top d-flex">
              <!--<p>{{ getDatetime(item.start) }}</p>-->
              <p><strong>{{ getTime(item.start, item.end) }}</strong></p>
            </v-col>
            <v-col cols="12" lg="auto" md="auto" class="pt-0">
              <p><strong>{{ item.metaInfo.title }}</strong></p>
              <p v-html="item.metaInfo.description"></p>
            </v-col>
          </v-row>
        </v-timeline-item>
      </v-timeline>
    </v-card-text>
  </v-card>
</template>

<script>
import { DateMixins } from "_shared/helpers";

export default {
  props: {
    headline: String,
    center: Boolean,
    smallheadline: Boolean,
    AgendaItems: { required: true },
  },
  mixins: [DateMixins],
};
</script>

<style lang="scss" scoped>
  p {
    margin-bottom: 0px;
    color: black;
  }



</style>