<template>
  <v-card
    flat
    color="transparent"
    class="d-flex flex-column align-content-start"
  >
    <v-container class="d-flex flex-column my-10">
      <v-card-title class="justify-sm-center mb-10">
        <h1 class="text-md-center component-header" v-html="headline"></h1>
      </v-card-title>
      <v-card-text>
        <v-row class="justify-center mb-8">
          <v-col v-for="event in getEvents" :key="event.id" cols="auto">
            <EventItem :eventItem="event" />
            <!--<p>{{event.forcedState}}</p>-->
          </v-col>
        </v-row>
      </v-card-text>
    </v-container>
  </v-card>
</template>

<script>
import EventItem from "./EventItem";

export default {
  components: {
    EventItem,
  },
  
  props: {
    headline: String,
    EventItems: {required:true, type:Array},
  },
  computed: {
    getEvents()
    {
        return this.EventItems.filter((e) => e.forcedState != 'done');
    },
  }
};
</script>

<style>
</style>