import { loadLanguageAsync } from '.'

export const storei18n = {
    namespaced: true,
    state: {
        current: undefined,
        available: []
    },
    getters: {
        getCurrent(state) {
            if (state.current === undefined) {
                if (window.localStorage.getItem('locale') == undefined) {
                    window.localStorage.setItem('locale', window.navigator.language);
                    state.current = state.current = window.localStorage.getItem('locale');
                }
                else
                    state.current = window.localStorage.getItem('locale')
                return state.current
            } else {
                return state.current
            }
        },
        Languages(state) {
            if (state.available.length == 0)
                window.axios.get('api/webapp/i18n/all').then((res) => state.available = res.data);

            return state.available;
        }
    },
    mutations: {
        SET_Language(state, payload) {
            window.localStorage.setItem('locale', payload)
            state.current = payload;
        },
        SET_Available(state, payload) {
            state.available = payload
        }
    },
    actions: {
        async setLanguage({ commit }, payload) {
            commit('SET_Language', payload.lang);
            await loadLanguageAsync(payload.lang, payload.clientid)
        }
    }
}