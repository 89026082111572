/* eslint-disable no-unused-vars */

import Oidc, { User, WebStorageStateStore } from "oidc-client"
import Vue from 'vue'
import { storeAccount } from './store'

let config = {
    client_id: "",
    scope: "",
    authority: process.env.VUE_APP_ID4Server,
    redirect_uri: process.env.VUE_APP_SpaClient + "/account/callback",
    automaticSilentRenew: true,
    silent_redirect_uri: process.env.VUE_APP_SpaClient + "/account/silentrenew",
    response_type: "code",
    response_mode: "query",
    post_logout_redirect_uri: process.env.VUE_APP_SpaClient,
};


export let mgr = {}
export let store = {}
export let router = {}


export default {
    install(vue, cfg) {
        ///init
        config = { ...config, ...cfg.mgr }
        store = cfg.store
        router = cfg.router

        Oidc.Log = console;

        const helpers = {
            login: async () => {
                return await mgr.signinRedirect();
            },
            logout: async () => {
                return await mgr.signoutRedirect()
            },
            register() {
                if (config.client_id === 'account.manager')
                    router.push({ name: 'Register' })
                else
                    window.location = process.env.VUE_APP_ID4Server + '/account/register';
            },
            silentSignIn: async () => {
                let user;
                try {
                    user =
                        await mgr.signinSilent();
                    console.log(user)
                    return user;
                } catch (e) {
                    console.log(e);
                    user = null;
                }
                return user;
            },
            initAsync: () => new Promise(function (resolve, reject) {
                mgr.getUser().then((user) => {
                    if (user == null) {
                        mgr.signinSilent().then(user => {
                            store.commit('account/SET_User', user);
                            resolve(user);
                        }).catch((e) => {
                            if (e.message == 'Unauthorized') {
                                store.commit('account/SET_ErrorMsg', 'Unauthorized')
                                router.push({ name: 'Error' }).catch(() => { });
                            }
                        });
                    }
                    else {
                        store.commit('account/SET_User', user);
                        resolve(user);
                    }
                }).catch((e) => { console.log(e) });
            }),
            init: () => {
                mgr.getUser().then((user) => {
                    if (user == null) {

                        mgr.signinSilent().then(user => {
                            store.commit('account/SET_User', user);
                        }).catch((e) => {

                            if (e.message == 'Unauthorized') {
                                store.commit('account/SET_ErrorMsg', 'Unauthorized')
                                router.push({ name: 'Error' }).catch(() => { });
                            }

                        });

                    } else {
                        store.commit('account/SET_User', user);
                    }
                }).catch((e) => { console.log(e) });
            }
        };

        Vue.account = helpers
        Vue.prototype.$account = helpers
        window.account = helpers;

        if (!store.hasModule('account')) {
            store.registerModule('account', storeAccount);
        }

        mgr = new Oidc.UserManager(config);

        mgr.events.addUserUnloaded(async () => {
            console.log("user unloaded")
            mgr.clearStaleState(new WebStorageStateStore({ store: window.localStorage }));
        });
        mgr.events.addUserSignedOut(async () => {
            console.log("oidc sign out")

            mgr.clearStaleState(new WebStorageStateStore({ store: window.localStorage }));
            await mgr.removeUser();
            store.commit('account/SET_User', null);
            
            if (store.state.client_id != 'account.manager')            
                router.push({ name: 'Home' })
        });
        
        mgr.events.addUserLoaded(async () => {
            console.log("user loaded")

            mgr.getUser().then((user) => {
                store.commit('account/SET_User', user);                
                // vue.StartAllHubs();            
            });
        });

        mgr.events.addAccessTokenExpiring(() => {
            console.log('Token Expiring')
        })
        mgr.events.addAccessTokenExpired(async () => {
            console.log('Token Expired')
            await mgr.removeUser();
            store.commit('account/SET_User', null);
        })
    }
}

export const account = {
    methods: {
        getRoles() {
            return store.getters['account/getRoles'];
        },
        hasRole(role) {
            var roles = store.getters['account/getRoles'];
            if (roles != undefined && Array.isArray(roles)) {
                if (roles.find(s => s === role))
                    return true;
            } else
                if (roles != undefined && roles == role)
                    return true;
            return false;
        },
        hasEvent(eventid) {
            var eventids = store.getters['account/getEventIds'];
            if (eventids != undefined && Array.isArray(eventids)) {
                if (eventids.find(s => s === eventid))
                    return true
            } else if (eventids != undefined && eventids == eventid)
                return true;
            return false
        },
        getQueryVariable(variable) {
            const query = window.location.search.substring(1);
            const vars = query.split("&");
            for (let i = 0; i < vars.length; i++) {
                let pair = vars[i].split("=");
                if (decodeURIComponent(pair[0]) == variable) {
                    return decodeURIComponent(pair[1]);
                }
            }
        },
    }
}
Vue.mixin(account)