// import EventBus from "_shared/EventBus";

export const machine =
{
    namespaced: true,
    state: {
        selectedMachineData: undefined,
        selectedMachine: undefined,
        machines: [],
        header: 'appshell',
        roomStateInfo: undefined,
        category: undefined,
        syncContent:undefined,
        syncData: undefined
    },
    mutations: {
        SET_SyncContent(state, payload){
            state.syncContent=payload;
        },
        SET_Machines(state, payload) {
            state.machines = payload;
        },
        SET_MachineData(state, payload) {
            state.selectedMachineData = payload;
        },
        SET_SelectedMachine(state, payload) {
            if (payload != null)
                state.header = payload.color;
            else
                state.header = 'appshell';

            state.selectedMachine = payload;
        },
        SET_RoomStateInfo(state, payload) {
            state.roomStateInfo = payload;
        },
        SET_SyncData(state, payload) {
            state.syncData = payload;
        },
        SET_Category(state, payload) {
            state.category = payload           
        }
    },
    getters: {
        GET_Machines(state) {
            return state.machines;
        },
        GET_SelectedMachine(state) {
            return state.selectedMachine
        },
        GET_SelectedMachineData(state) {
            return state.selectedMachineData
        },
        GET_Category(state)
        {
            return state.category;
        }, 
        GET_SyncData(state)
        { 
            return state.syncData;
        },
        GET_SyncContent(state)
        { 
            return state.syncContent;
        },
        GET_Tabs(state) {

            if (state.selectedMachineData != undefined) {
                var appbaritems = [];
                let props = Object.getOwnPropertyNames(state.selectedMachineData);//state.selectedMachineData.content;
                   
                props.forEach(prop => {
                    var item =  state.selectedMachineData[prop];
                    
                    if (item && item.title){// && item.order && item.title && item.state) {
                        appbaritems.push(item);                        
                    }
                });
                appbaritems.sort(function (a, b) {
                    return a.order - b.order;
                });

                return appbaritems;
            } else
                return [];

        },
               
    },
    actions: {
        async getMachines({ commit }) {
            commit('SET_Machines', (await window.axios.get('/api/machines')).data);
        },
        async loadMachineData({ commit, rootGetters }, item) {
            if (item != undefined) {
                commit('SET_SelectedMachine', item);               

                let data = (await window.axios.get('/api/machine/' + item.id + '/data?lang=' + rootGetters['i18n/getCurrent'])).data;
                commit('SET_MachineData', data);

                // EventBus.$emit("machines.select");
            }
        }
    }
}
