import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import de from 'vuetify/es5/locale/de';

Vue.use(Vuetify);

export default new Vuetify({
  /*icons: {
    iconfont: 'fa',
  },*/
  theme: {
    options: {
      customProperties: true,
      variations: true
    },
    dark: false,
    themes: {
      light: {
        appshell:"#41535D",
        primary: '#41535D',
        secondary: '#41535D',
        accent: '#41535D',
        error: '#FF0000',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        tertiary: '#e4e8ec',
        footer: '#fff',
        footerInvers: '#fff',
        wirtgen: '#fb5a02',
        kleemann: '#1755a2',
      },
      dark: {
        primary: '#41535D',
        secondary: '#41535D',
        accent: '#41535D',
        error: '#FF0000',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        tertiary: '#272727',
        footer: '#fff',
        footerInvers: '#000',
        wirtgen: '#fb5a02',
        kleemann: '#1755a2',
      },
    },
  },
  lang: {
    locales: { de },
    current: 'de',
  },
});
