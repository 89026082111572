<template>
  <div
    class="div-100 d-flex flex-column align-center justify-center text-center"
  >
    <v-card-subtitle>
      <h1 v-if="roomState === 'done'" v-html="$t('waitingroom.closed')"></h1>
      <h1
        v-else-if="roomState === 'pause'"
        v-html="$t('waitingroom.pause')"
      ></h1>
      <h1
        v-else-if="roomState === 'upcoming'"
        v-html="$t('waitingroom.title')"
      ></h1>
      <h1 v-else-if="roomState === 'live'">Live</h1>
    </v-card-subtitle>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default { 
  computed: {
    ...mapGetters("notification", ["roomState"]),
  },
  watch: {
    roomState: {
      handler(value) {
        if (value === "live") {
          this.$router.replace({
            name: "Live",            
          });
        }
      },
    },
  },
};
</script>

<style scoped>
h1 {
  line-height: 2.8em;
  color:var(--v-primary-base);
}
</style>