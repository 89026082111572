<template>
  <v-overlay absolute >
    <v-container>
      <v-card rounded="xl" light color="#f7f7f7"  class="pa-8" v-click-outside="onClickOutside">
        <!-- :max-width="dataOverlay.items.length == 4 ? 768 : undefined" -->        
        <v-row>
          <v-col cols="10">
            <v-card-subtitle class="ml-8">
              <h2>{{ dataOverlay.header }}</h2>
            </v-card-subtitle>            
          </v-col>
          <v-col cols="2" class="d-flex">
            <v-btn
              @click="$emit('close')"
              :color="$store.state.machine.header"
              small
              elevation="0"
              fab
              class="ml-auto mt-n3 mr-n3"
            >
              <v-icon class="white--text">mdi-close</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12">
            <v-card-text class="d-flex justify-center">
              <v-item-group>
                <v-row class="mx-auto d-flex">
                  <v-col
                    cols="auto"
                    v-for="item in dataOverlay.items"
                    :key="item.id"
                    class="d-flex justify-center pa-6"
                  >
                    <v-card
                      outlined
                      rounded="xl"
                      @click.native="$emit('collectionItemSelected', item)"
                      class="ma-1"
                    >
                      <v-img
                        class="d-flex align-end"
                        :src="getUrl(item.preview)"
                        width="256"
                        
                        height="100%"
                        :aspect-ratio="1.41"
                      >
                        <v-sheet class="ml-3 mb-5">
                          <v-card-subtitle
                            class="pl-2 pa-0"
                            style="max-width: 256px; font-weight: bold"
                          >
                            <p>{{ item.header }}</p>
                          </v-card-subtitle>
                        </v-sheet>
                      </v-img>
                    </v-card>
                  </v-col>
                </v-row>
              </v-item-group>
            </v-card-text>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </v-overlay>
</template>

<script>
import { urlHelper } from "_shared/helpers";
// import IconClose from '../IconClose.vue';

export default {
  // components: { IconClose },
  props: {
    dataOverlay: { type: Object, required: true },
  },
  mixins: [urlHelper],
  computed: {
    collectionItems() {
      return this.dataOverlay.items;
    },
  },  
  methods: {
    onClickOutside() {
      this.$emit('close')
      console.log("onClickOutside");
    },
  },
};
</script>

<style scoped>
p {
  margin-bottom: 0px;
}
</style>