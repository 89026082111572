export const storeNotification = {
    namespaced: true,
    state: {
        EventStateItem: null
    },
    getters: {
        Agenda(state) {
            return state.EventStateItem?.agendaItem;
        },        
        roomState(state) { 
           // if (state.EventStateItem == null)
            //    return 'done';
            return state.EventStateItem?.roomState;
        },
        active(state)
        {
            return state.EventStateItem?.active;
        }
    },
    mutations: {
        SET_EventStateItem(state, payload) {            
            state.EventStateItem = payload;
        },
    },
    actions: {
    }
}