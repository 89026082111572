<template>
    <div v-if="show" class="dialog">
        <div class="dialog-overlay" @click="hide()">
            <icon-close style="position: absolute; right: 12px; top: 12px;" @close="hide()"/>
        </div>
        <div class="dialog-content">
            <table style="width: 100%" @click="hide()"><tr><td>{{header}}</td></tr></table>
            <div style="height: 3vh;" @click="hide()"/>
            <div style="width: 100%; height: 60vh;">
                <agile :dots="assets.length > 1" :navButtons="assets.length > 1">
                    <template v-slot:prevButton>
                        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" style="transform: rotate(180deg);">
                            <path :fill="currentColor" d="M32.839 24L11.947 3.108l1.607-1.607L36.053 24 13.554 46.499l-1.607-1.607z"></path>
                        </svg>
                    </template>
                    <template v-slot:nextButton>
                        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48">
                            <path :fill="currentColor" d="M32.839 24L11.947 3.108l1.607-1.607L36.053 24 13.554 46.499l-1.607-1.607z"></path>
                        </svg>
                    </template>
                    <div class="slide" v-for="(item, idx) in assets" :key="idx">
                        <v-img v-if="item.type.includes('image')" :src="getUrl(item.link)" contain style="max-height: 70vh">
                            <template v-slot:placeholder>
                                <v-row class="fill-height ma-0" align="center" justify="center">
                                    <v-progress-circular indeterminate color="#f3f3f3"></v-progress-circular>
                                </v-row>
                            </template>
                        </v-img>
                        <video v-if="item.type.includes('video')" :src="getUrl(item.link)" controls :class="videoClass"></video>
                        <iframe v-if="item.type.includes('pdf')" :src="getUrl(item.link)" :class="pdfClass"></iframe>
                        <iframe v-if="item.type.includes('html')" :src="item.link" :class="pdfClass"></iframe>
                    </div>
                </agile>
            </div>
        </div>
    </div>
</template>

<script>

function getCSSRulesFor(el) {
    var sheets = document.styleSheets, ret = [];
    el.matches = el.matches || el.webkitMatchesSelector || el.mozMatchesSelector 
        || el.msMatchesSelector || el.oMatchesSelector;
    for (var i in sheets) {
        var rules = sheets[i].rules || sheets[i].cssRules;
        for (var r in rules) {
            if (el.matches(rules[r].selectorText)) {
                ret.push(rules[r]);
            }
        }
    }
    return ret;
}

import { mapState } from "vuex";
import { urlHelper } from "_shared/helpers";
import { VueAgile } from 'vue-agile'
import IconClose from '../IconClose.vue';
var mime = require('mime-types');

export default {
    name: 'SliderPopup',
    components: {
        agile: VueAgile,
        IconClose,
    },
    props:{
        dataOverlay:{type:Object, required:true},
    },
    mixins: [urlHelper],
    data() {
        return {
            show: false,
            header: '',
            assets: [],
        }
    },
    mounted(){
        this.showContent(this.dataOverlay);
    },
    computed: mapState ({
        currentColor: state => state.currentColor,
        videoClass() {
            return this.$vuetify.breakpoint.smAndDown ? "videoframe-small" : "videoframe-large";
        },
        pdfClass() {
            return this.$vuetify.breakpoint.smAndDown ? "pdfframe-small" : "pdfframe-large";
        }
    }),
    methods: {
        showContent(data) {
            if (data) {
                try {
                    this.header = data.header;
                    console.log(this.header);
                    var assets = [];
                    if (data.files) {
                        data.files.forEach(file => {
                            var mtype = mime.lookup(file);
                            if (file.includes("http")) {
                                mtype = "html";
                            }
                            if (mtype) {
                                assets.push({ type: mtype, link: file });
                            }
                        });
                    }
                    this.assets = assets;

                    // set components color
                    setTimeout(() => {
                        document.getElementsByClassName('agile__dot').forEach(element => {
                            let child = element.children[0];
                            if (child) {
                                getCSSRulesFor(child).forEach(rule => {
                                    if (rule.selectorText == '.agile__dot--current button, .agile__dot:hover button') {
                                        rule.style.setProperty('background-color', this.currentColor);
                                    }
                                    else if (rule.selectorText == '.agile__dot button') {
                                        rule.style.setProperty('border-color', this.currentColor);
                                    }
                                })
                            }
                        });
                    }, 200);

                    this.show = true;
                }
                catch (err) {
                    console.log(err);
                }
            }
        },
        hide() {
            this.show = false;
            this.$emit('close');
        }
    }
}
</script>

<style scoped>

td {
    text-align: center;
    color: white;
    font-size: 24px;
}

.dialog {
    position: absolute;
    top:0px;
    left: 0px;
    width: 100%;
    height: 100%;
}

.dialog-overlay {
    position: absolute;
    top:0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgb(0,0,0,0.8);
}

.dialog-content {
    position: absolute;
    top: 5vh;
    width: 100%;
    height: 75vh;
    color: white;
    background-color: transparent;
}

.videoframe-large {
    width: 80%;
    max-height: 70vh;
}

.videoframe-small {
    width: 100%;
    max-height: 70vh;
}

.pdfframe-large {
    width: 80%;
    height: 70vh;
    border: none;
}

.pdfframe-small {
    width: 100%;
    height: 70vh;
    border: none;
}

</style>

<style>
.agile__nav-button {
  background: transparent;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 24px;
  height: 100%;
  position: absolute;
  top: 0;
  transition-duration: 0.3s;
  width: 80px;
}
.agile__nav-button:hover {
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 1;
}
.agile__nav-button--prev {
  left: 0;
}
.agile__nav-button--next {
  right: 0;
}
.agile__dots {
  bottom: -40px;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
}
.agile__dot {
  margin: 0 10px;
}
.agile__dot button {
  background-color: transparent;
  border-width: 1px;
  border-style: solid;
  border-radius: 50%;
  cursor: pointer;
  display: block;
  height: 10px;
  font-size: 0;
  line-height: 0;
  margin: 0;
  padding: 0;
  transition-duration: 0.3s;
  width: 10px;
}

.agile__dot--current button, .agile__dot:hover button {
  background-color: #fb5a02;
}

.slide {
	display: flex;
	object-fit: contain;
    align-content: center;
    justify-content: center;
}

</style>
