import moment from "moment-timezone";

export const DateMixins = {
  methods: {
    getDatetime(date) {      
      return moment.tz(moment.utc(date), 'Europe/Berlin').format("D.M.Y");
    },
    getTimeSingle(datetime) {
      return moment.tz(moment.utc(datetime), 'Europe/Berlin').format("HH:mm")
    },
    getTime(start, end) {      
      return (
        moment.tz(moment.utc(start), 'Europe/Berlin').format("HH:mm") + " - " + moment.tz(moment.utc(end), 'Europe/Berlin').format("HH:mm")        
      );
    },
    formatDate(date, showYear=true) {
      if (date);
      if(showYear)
      return moment.tz(moment.utc(date), 'Europe/Berlin').format('D.M.Y HH:mm');
      else return moment.tz(moment.utc(date), 'Europe/Berlin').format('D.M HH:mm');
    },
  },
}

export const urlHelper =
{
  methods: {
    getUrl(path) {      
      return process.env.VUE_APP_ID4Server + path;
    },
  },

}

export default {}