<template>
  <div class="home div-100" style="background-color: #e4e8ec" ref="container">
    <!-- <v-img
      :src="require('_shared/assets/bg.jpg')"
      height="100vh"
      style="top: -48px"
    >      
    <div class="d-flex flex-column align-center div-100 justify-center px-16">
        <h1 class="white--text event-title pb-5" v-html="$t('page.title')"></h1>
        <h1 class="white--text event-txt pb-1" v-html="$t('page.txt')"></h1>
        <h1 class="white--text event-date" v-html="$t('page.date')"></h1>
        
      </div>
    </v-img> -->

    <RegisterOrLogin />
    <div class="d-flex flex-column">
      <div  id="events">
        <EventsFrame
          :EventItems="EventItems"
          :headline="$t('page.events.headline')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import RegisterOrLogin from "./components/RegisterOrLogin";
import EventsFrame from "../components/Events/EventsFrame";

export default {
  name: "HomeCopy",
  components: {
    RegisterOrLogin,
    EventsFrame,
  },
  data: () => ({
    EventItems: [],
  }),
  async beforeCreate() {
    ///show only Active Events | all==false
    this.EventItems = await this.$store.dispatch("getEventItems", false);
  },
};
</script>

<style lang="scss" scoped>
.event-title {
  font-family: "Bold";
  font-weight: bolder;
  font-size: 100px;
  line-height: 1;
  padding-bottom: 100px;
}

.event-subtitle {
  font-family: "Thin";
  font-size: 25px;
  font-weight: 100;
  padding-bottom: 24px;
}
.event-date {
  font-family: "Thin";
  font-size: 25px;
  font-weight: 400;
  line-height: 1.2;
}
.event-txt {
  font-family: "Thin";
  font-size: 50px;
  font-weight: 400;
  line-height: 1.2;
}
.event-title,
.event-subtitle,
.event-txt,
.event-date {
  text-align: center;
}

.img-wrapper {
  width: 500px;
}
.img-logo {
  height: 24px;
}

@import "~vuetify/src/styles/settings/_variables";
@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .event-title {
    font-size: 50px;
  }
  .event-subtitle {
    font-size: 20px;
  }
  .event-date {
    font-size: 25px;
  }
  .event-txt {
    font-size: 35px;
  }
  .img-wrapper {
    width: 100px;
  }
  .img-logo {
    height: 12px;
  }
}

@media #{map-get($display-breakpoints, 'xs-only')} {
  .event-title {
    font-size: 50px;
  }
  .event-subtitle {
    font-size: 20px;
  }
  .event-date {
    font-size: 25px;
  }
  .event-txt {
    font-size: 25px;
  }
  .img-wrapper {
    width: 100px;
  }
  .img-logo {
    height: 12px;
  }
  .h1 {
    font-size: 20px;
  }
}
</style>
