/*eslint-disable no-unused-vars */
import Vue from 'vue'
import VueRouter from 'vue-router'

import { loadLanguageAsync } from '_shared/i18n'

import { routesAccount } from '_shared/account/routes'
import { account } from '_shared/account'

import store from '../store'
import EventBus from "_shared/EventBus";

import Home from '../views/Home'
import EventView from '../views/pages/EventView'
import WaitingRoomView from '../views/pages/WaitingRoomView'
// import LiveContainerView from '../views/pages/LiveContainerView'

import Showroom from '../views/pages/Showroom'


Vue.use(VueRouter)


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { requireAuth: false },
    beforeEnter: (to, from, next) => {
      store.commit("SET_EventId", null);
      store.commit("notification/SET_EventStateItem", null);
      next()
    },
  },  
  {
    path: '/:eventId',
    component: { render(c) { return c('router-view') } },
    meta: { requireAuth: false },
    children: [
      {
        path: '',
        name: 'Event',
        redirect:'waitingroom',
        component: EventView,
        meta: { requireAuth: false }
      },
      {
        path: 'waitingroom',
        name: 'WaitingRoom',
        component: WaitingRoomView,
        meta: { requireAuth: true },
        beforeEnter: (to, from, next) => {
          if (store.getters['notification/roomState'] === 'live' || account.methods.hasRole('user.test'))
            router.replace({ name: 'Live', params: { eventId: to.params.eventId } });
          else
            next();
        }
      },
      {
        path: 'live',
        name: 'Live',        
        component: Showroom,
        meta: { requireAuth: true },
        beforeEnter: (to, from, next) => {
          if (store.getters['notification/roomState'] === 'live' || account.methods.hasRole('user.test'))
            next();
          else
            router.replace({ name: 'WaitingRoom', params: { eventId: to.params.eventId } })
        },
        children: [
          {
            path: 'showroom',
            name: 'Showroom',
            component: Showroom,
            meta: { requireAuth: true },
          },
          
        ]
      }
    ]
  },
  ...routesAccount,
  {
    ///Default routes
    path: '*',
    meta: { requireAuth: false },
    beforeEnter: () => {
      router.push('/')
    }
  },
]


import goTo from 'vuetify/es5/services/goto'

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0

    if (to.matched.some(({ name }) => name == 'Live' || name == 'Mediathek')) {
      return { x: 0, y: 0 }
    } else
      if (to.hash) {
        scrollTo = to.hash
      } else if (savedPosition) {
        scrollTo = savedPosition.y
      }

    return goTo(scrollTo)
  },
})


router.afterEach(async () => {
  await loadLanguageAsync(store.getters['i18n/getCurrent'], store.state.client_id)
})

router.beforeEach(async (to, from, next) => {

  if (from === VueRouter.START_LOCATION) {
    console.log("start")
  }

  if (to.path == "account/callback" || to.path == "account/slilentrenew") { next(false); return; }


  if (to.matched.some(record => record.meta.requireAuth))
    if (store.getters['account/isLogIn'] == true) {

      await Vue.StartAllHubs();

      if (to.name == "Event" || to.params.eventId != null)
        next(await GetEvent(to, from, next, true))
      else
        next()
    }
    else {
      var user = await store.dispatch('account/getUser');

      if (user == null) {
        next(false);
        window.account.login();
      }
      else
        next(to)
    }
  else {
    if (to.name == "Event" || to.params.eventId != null)
      next(await GetEvent(to, from, next, true))
    else
      next()
  }


  //return;
})


async function GetEvent(to, from, next, isPublic) {
  let res = await window.axios.get(`api/event/ExistEvent?eventid=${to.params.eventId}&isActive=${true}`);
  // console.log(res.data)
  if (res.data.exist == true && (account.methods.hasEvent(res.data.eventId) || isPublic)) {
    if (res.data.active == false)
      router.replace('/');
    else {
      if (store.state.notification.EventStateItem == null) {
        store.commit('SET_EventId', res.data.eventId);
        //store.commit('notification/SET_EventStateItem', await window.notification.getEventItemState(res.data.eventId));    
        store.commit('notification/SET_EventStateItem', (await window.axios.get(`api/event/GetEventState?eventid=${res.data.eventId}`)).data);
      }
    }
  }
  else {
    console.log("Event not allowed")
    next(router.push('/'));
    return false;
  }

}

export default router
