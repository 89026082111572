<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 51 50" style="cursor: pointer" @click="$emit('close')">
    <path fill-rule="evenodd"  :fill="color"
    d="M42.971,7.522 C52.667,17.218 52.667,32.938 42.971,42.634 C33.275,52.330 17.555,52.330 7.859,42.634 C-1.837,32.938 -1.837,17.218 7.859,7.522 C17.555,-2.174 33.275,-2.174 42.971,7.522 Z"/>
    <path fill-rule="evenodd"  fill="rgb(243, 243, 243)"
    d="M17.831,19.827 L31.033,33.030 L33.234,30.830 L20.031,17.627 L17.831,19.827 Z"/>
    <path fill-rule="evenodd"  fill="rgb(243, 243, 243)"
    d="M17.831,30.830 L20.031,33.030 L33.234,19.827 L31.033,17.627 L17.831,30.830 Z"/>
  </svg>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    width: { type: [Number, String], default: '42px' },
    height: {type: [Number, String], default: '42px' },
  },
  computed: mapState ({
    color: state => state.currentColor,
  }),
}
</script>