<template>
  <div class="d-flex flex-row" :class="vertical ? 'flex-column' : 'flex-row'">
    <v-toolbar
      flat
      dense
      color="transparent"
      :class="hasRole('manager.owner') ? '' : 'client'"
      class="d-flex flex-column"
    >
      <!-- <a
      flat
      @click="setHome()"
      v-if="$store.getters['machine/GET_SelectedMachine'] != null"      
    >
      <v-toolbar-title class="white--text font-weight-bold">
        {{ $store.getters["machine/GET_SelectedMachine"].name }}
      </v-toolbar-title>
    </a> -->
    </v-toolbar>

    <v-tabs
      class="white--text"
      :vertical="vertical"
      color="white"
      background-color="transparent"
      align-with-title
      active-class="tabs-active"
      center-active
      :hide-slider="false"
      @change="selectedTab"
      dark
      v-model="activeTab"
      optional
      :show-arrows="GET_Tabs != undefined && GET_Tabs.length != 0"
    >
      <v-tab
        class="white--text"
        v-for="tab in GET_Tabs"
        :key="tab.title"
        :href="'#' + tab.title"
      >
        {{ tab.title }}
      </v-tab>
    </v-tabs>
  </div>
  <!-- </v-toolbar> -->
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    vertical: { Type: Boolean, value: false, required: false },
  },
  data: () => ({
    activeTab: undefined,
  }),
  methods: {
    async selectedTab(tabname) {
      let item = this.$store.getters["machine/GET_Tabs"].find(
        (s) => s.title == tabname
      );
      if (item != undefined) {
        this.$store.commit("machine/SET_Category", item.state);
        await this.$syncroomhub.send_Category(item.state);
      }
    },
    async setHome() {
      this.activeTab = undefined;
      this.$store.commit("machine/SET_Category", 0);
      await this.$syncroomhub.send_Category(0);
    },
  },
  computed: {
    ...mapGetters("machine", ["GET_Category", "GET_Tabs"]),
  },
  watch: {
    GET_Tabs: {
      handler(val) {
        if (val != undefined) {
          var tab = this.$store.getters["machine/GET_Tabs"].find(
            (s) => s.state == this.GET_Category
          );
          if (tab != undefined) this.activeTab = tab.title;
          else this.activeTab = undefined;
        }
      },
    },
    GET_Category: {
      handler(val) {
        var tab = this.$store.getters["machine/GET_Tabs"].find(
          (s) => s.state == val
        );
        if (tab != undefined) this.activeTab = tab.title;
        else this.activeTab = undefined;
      },
    },
  },
};
</script>

<style>
.client {
  pointer-events: none;
}
</style>